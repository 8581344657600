var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('NewButton',{attrs:{"horizontal":true,"label":"Create User Group"},on:{"click":function($event){return _vm.showUserGroupDialog('new')}}}),_c('v-text-field',{attrs:{"solo":"","id":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.userGroups,"search":_vm.search,"loading":_vm.loading},on:{"click:row":_vm.view},scopedSlots:_vm._u([{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.attributes.isAdmin)?_c('v-chip',{attrs:{"color":"accent","small":""}},[_vm._v("Admin")]):_c('v-chip',{attrs:{"small":""}},[_vm._v("Normal")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"created","date":item.attributes.created,"user":item.attributes.userCreated}})]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_c('DateUserCell',{attrs:{"action":"modified","date":item.attributes.modified,"user":item.attributes.userModified}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ButtonEditAction',{attrs:{"loading":_vm.saving && _vm.userGroupId === item.id,"disabled":_vm.busy && _vm.userGroupId === item.id},on:{"click":function($event){return _vm.showUserGroupDialog(item.id)}}}),_c('ButtonDeleteAction',{attrs:{"loading":_vm.deleting && _vm.userGroupId === item.id,"disabled":_vm.busy && _vm.userGroupId === item.id},on:{"click":function($event){return _vm.deleteUserGroup(item.id)}}})]}}])}),(_vm.userGroupDialogShown)?_c('UserGroupDialog',{attrs:{"userGroupId":_vm.userGroupId,"newItem":_vm.newItem},on:{"close":_vm.closeUserGroupDialog}}):_vm._e(),_c('NewButton',{on:{"click":function($event){return _vm.showUserGroupDialog('new')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }








import Vue from 'vue';
import Component from 'vue-class-component';
import UserGroupList from '@/components/user-groups/UserGroupList.vue';
import Page from '@/components/ui/Page.vue';
import PageTitle from '@/components/ui/PageTitle.vue';

@Component({
  components: {PageTitle, UserGroupList, Page},
})
export default class UserGroups extends Vue {}

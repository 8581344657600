
































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, UserGroupResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {userGroupModule} from '@/store/modules';
import Dialog from '@/components/ui/Dialog.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';

@Component({
  components: {DialogAlert, Dialog},
})
export default class UserGroupDialog extends Vue {
  @Prop() userGroupId!: string;
  @Prop({default: false}) newItem!: boolean;
  private alert: AlertMessage | null = null;

  get userGroup(): UserGroupResource {
    return userGroupModule.find(this.userGroupId);
  }

  get loading(): boolean {
    return userGroupModule.loading;
  }

  get saving(): boolean {
    return userGroupModule.saving;
  }

  async mounted() {
    try {
      console.log(this.userGroupId);
      await userGroupModule.loadById(this.userGroupId);
    } catch (err) {
      console.error(
        `There was an error loading user group ${this.userGroupId}`
      );
    }
  }

  async save() {
    try {
      await userGroupModule.save(this.userGroupId);
      this.$emit('close', 'User Group saved successfully');
    } catch (err) {
      this.alert = {message: 'Could not save user group', type: 'error'};
    }
  }
  cancel() {
    this.$emit('close');
  }
}





























































import Vue from 'vue';
import Component from 'vue-class-component';
import {Snackbar, UserGroupResource, UserResource} from '@/interfaces';
import {userGroupModule, userModule} from '@/store/modules';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import UserGroupDialog from '@/components/user-groups/UserGroupDialog.vue';
import NewButton from '@/components/ui/NewButton.vue';
import DateUserCell from '@/components/ui/DateUserCell.vue';

@Component({
  components: {
    DateUserCell,
    NewButton,
    UserGroupDialog,
    ButtonDeleteAction,
    ButtonEditAction,
  },
  props: [],
})
export default class UserGroupList extends Vue {
  private search = '';
  private headers = [
    {
      text: 'Name',
      align: 'start',
      value: 'attributes.label',
    },
    {text: 'Type', value: 'admin'},
    {text: 'Created', value: 'created'},
    {text: 'Modified', value: 'modified'},
    {text: 'Actions', value: 'actions'},
  ];
  private snackbar: Snackbar = {visible: false, message: ''};
  private userGroupId: null | string = null;
  private userGroupDialogShown = false;
  private newItem = false;

  get busy(): boolean {
    return userGroupModule.busy;
  }

  get deleting(): boolean {
    return userGroupModule.deleting;
  }

  get loading(): boolean {
    return userGroupModule.loading;
  }

  get saving(): boolean {
    return userModule.saving;
  }

  get userGroups(): UserGroupResource[] {
    return userGroupModule.list;
  }

  async mounted() {
    await userGroupModule.loadMulti();
  }

  async showUserGroupDialog(userGroupId: string): Promise<void> {
    this.userGroupId = userGroupId;
    if (userGroupId === 'new') {
      await userGroupModule.loadById('new');
      const userGroup = await userGroupModule.save('new');
      this.userGroupId = userGroup.id;
      this.newItem = true;
    } else {
      this.newItem = false;
    }
    this.userGroupDialogShown = true;
  }

  closeUserGroupDialog(message?: string): void {
    this.userGroupDialogShown = false;
    if (message !== undefined) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteUserGroup(userGroupId: string): Promise<void> {
    this.userGroupId = userGroupId;
    const userGroup = userGroupModule.find(userGroupId);
    const result = confirm(
      `Are you sure you want to delete the user group ${userGroup.attributes.label}?`
    );
    if (result) {
      await userGroupModule.delete(userGroupId);
    }
  }
  view(item: UserResource): void {
    this.showUserGroupDialog(item.id);
  }
}
